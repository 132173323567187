import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get, map, sortBy } from 'lodash';
import dayjs from 'dayjs';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { buildAsyncReducers } from '../thunkTemplate';
import { kiosk } from '../initialState';
import { setLoader } from '../pages';

const getKioskData = createAsyncThunk(
  'kiosk/getKioskData',
  async (props, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      if (props.type === 'org') props.type = 'organization';

      let resource = null;
      let sites = null;

      const payload = await new WebAPIClient().GET(
        `/kiosk/${props.type}/${props.kioskCode}`
      );

      if (props.type === 'organization') {
        resource = get(payload, 'organization', {});
        sites = sortBy(get(payload, 'sites', []), 'name');
      } else if (props.type === 'site') {
        const site = get(payload, 'site', {});
        resource = site;
        sites = [site];
      }

      return {
        validLicense: true,
        resource,
        sites,
        meters: sortBy(get(payload, 'meters', []), 'name'),
      };
    } catch (err) {
      errorResponseToastr(err);
      dispatch(setLoader(false));
    }
  }
);

const refreshKioskMeters = createAsyncThunk(
  'kiosk/refreshKioskMeters',
  async (props, { dispatch }) => {
    try {
      dispatch(showLoading());
      if (props.type === 'org') props.type = 'organization';

      const meters = await Promise.all(
        props.devices.map((device) =>
          new WebAPIClient().GET(
            `/kiosk/${props.type}/${props.kioskCode}/${get(
              device,
              `${device.type_}_id`
            )}`
          )
        )
      );

      return { meters };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getLastMonthData = createAsyncThunk(
  'kiosk/getLastMonthData',
  async (props, { dispatch }) => {
    try {
      dispatch(showLoading());
      if (props.type === 'org') props.type = 'organization';
      let month = dayjs().subtract(1, 'month').startOf('month');

      let _rawData = await Promise.all(
        map(props.meters, (meter) =>
          new WebAPIClient().GET(
            `/kiosk/${props.type}/${props.kioskCode}/${
              meter.meter_id
            }/${month.format('YYYY-MM')}`
          )
        )
      );

      return { lastMonth: { rawData: _rawData } };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const { actions, reducer } = createSlice({
  name: 'kiosk',
  initialState: kiosk,
  reducers: {
    setImageIndex: (state, { payload }) => {
      let idx = null;

      if (state.images.selectedIdx === payload - 1) {
        idx = 0;
      } else {
        idx = state.images.selectedIdx + 1;
      }

      return {
        ...state,
        images: {
          ...state.images,
          selectedIdx: idx,
        },
      };
    },
    setSelectedEnergy: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          selectedEnergy: payload,
        },
      };
    },
    setSlideHeight: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          slideHeight: payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getKioskData,
      refreshKioskMeters,
      getLastMonthData,
    ]);
  },
});

const { setImageIndex, setSelectedEnergy, setSlideHeight } = actions;

export {
  getKioskData,
  refreshKioskMeters,
  getLastMonthData,
  setImageIndex,
  setSelectedEnergy,
  setSlideHeight,
};
export default reducer;
