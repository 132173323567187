import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
const FORMATS = [
  'M/D/YYYY H:mm',
  'MM/DD/YY HH:mm', // Format: %m/%d/%y %H:%M
  'MM/DD/YYYY HH:mm', // Format: %m/%d/%Y %H:%M
  'YYYY/MM/DD hh:mm:ss A', // Format: %Y/%m/%d %I:%M:%S %p
];

function lastMonth(zoneName) {
  const lastMonth = dayjs().tz(zoneName).subtract(1, 'month');
  return { start: lastMonth.startOf('month'), end: lastMonth.endOf('month') };
}

function thisMonth(zoneName) {
  const thisMonth = dayjs().tz(zoneName);
  return { start: thisMonth.startOf('month'), end: thisMonth.endOf('month') };
}

function lastWeek(zoneName) {
  const lastWeek = dayjs().tz(zoneName).subtract(1, 'week');
  return { start: lastWeek.startOf('week'), end: lastWeek.endOf('week') };
}

function thisWeek(zoneName) {
  const thisWeek = dayjs().tz(zoneName);
  return { start: thisWeek.startOf('week'), end: thisWeek.endOf('week') };
}

function yesterday(zoneName) {
  const yesterday = dayjs().tz(zoneName).subtract(1, 'day');
  return { start: yesterday.startOf('day'), end: yesterday.endOf('day') };
}

function today(zoneName) {
  const today = dayjs().tz(zoneName);
  return { start: today.startOf('day'), end: today.endOf('day') };
}

function lastYear(zoneName) {
  const lastYear = dayjs().tz(zoneName).subtract(1, 'year');
  return { start: lastYear.startOf('year'), end: lastYear.endOf('year') };
}

function thisYear(zoneName) {
  const thisYear = dayjs().tz(zoneName);
  return { start: thisYear.startOf('year'), end: thisYear.endOf('year') };
}

function getLocalTimzone() {
  const now = dayjs();
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const name = now.tz(zone).format('z');
  const offset = now.tz(zone).utcOffset() / 60;

  return { name, zone, offset };
}

/**
 * Function to detect format, convert to Unix time, and apply timezone offset
 * @param {string} timestamp - The date string to parse
 * @param {number} timezoneOffset - The timezone offset in hours (e.g., -5 for EST)
 * @returns {number | null} - Unix timestamp (in seconds) or null if no format matches
 */
function convertStringToUnix(timestamp, timezoneOffset = 0) {
  let parsedDate = null;

  if (typeof timestamp !== 'string' || timestamp === '') {
    return null;
  }

  for (const format of FORMATS) {
    const date = dayjs(timestamp, format, true); // `true` ensures strict parsing
    if (date.isValid()) {
      parsedDate = date;
      break;
    }
  }

  if (parsedDate) {
    // Adjust the timezone offset (if provided) and convert to Unix timestamp
    let adjustedDate = parsedDate.add(timezoneOffset, 'hour');
    adjustedDate = adjustedDate
      .minute(adjustedDate.minute() - (adjustedDate.minute() % 15))
      .second(0)
      .millisecond(0);
    return adjustedDate.unix(); // Return Unix time (in seconds)
  } else {
    throw new Error(`Invalid format : ${timestamp}`);
  }
}

/**
 * Convert Unix timestamp to a timezone-aware dayjs object
 * @param {number} timestampInSeconds - Unix timestamp in seconds
 * @param {string} tz - Timezone string (e.g., 'America/New_York', 'Europe/London')
 * @returns {dayjs.Dayjs} - A dayjs object aware of the specified timezone
 */
function convertToTimezoneAwareDayjs(timestampInSeconds, tz) {
  return dayjs.unix(timestampInSeconds).tz(tz);
}

const getLatestInterval = () => {
  let now = dayjs();
  return now
    .minute(now.minute() - (now.minute() % 15))
    .second(0)
    .millisecond(0);
};

const dateStringToDayJS = (dateString) => {
  return dayjs(dateString?.substring(0, 19) + dateString?.slice(-6));
};

export {
  today,
  yesterday,
  lastWeek,
  thisMonth,
  lastMonth,
  lastYear,
  thisYear,
  thisWeek,
  getLocalTimzone,
  convertStringToUnix,
  convertToTimezoneAwareDayjs,
  getLatestInterval,
  dateStringToDayJS,
};
